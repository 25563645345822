<aside class="fixed top-0 left-0 z-20 w-64 h-screen pt-14 transition-transform bg-gray translate-x-0 border-r border-secondary" aria-label="Sidenav"
	id="drawer-navigation">
	<div class="overflow-y-auto py-5 px-3 h-full bg-gray">
		<ul class="space-y-2">
			<li>
				<button pButton routerLink="dashboard" routerLinkActive #rlaDashbaord="routerLinkActive" class="w-full text-left"
					[ngClass]="{'': rlaDashbaord.isActive ,'p-button-text': !rlaDashbaord.isActive }">Dashboard</button>
			</li>
		</ul>
		<ul class="space-y-2 pt-2 mt-2 border-t border-secondary">
			<li>
				<button pButton routerLink="organizations" routerLinkActive #rlaOrganizations="routerLinkActive" class="w-full text-left"
					[ngClass]="{'': rlaOrganizations.isActive ,'p-button-text': !rlaOrganizations.isActive }">Organizations</button>
			</li>
			<!-- <li>
				<a pButton routerLink="applications" routerLinkActive #rlaApplications="routerLinkActive" class="w-full"
					[ngClass]="{'': rlaApplications.isActive ,'p-button-text': !rlaApplications.isActive }">Applications</a>
			</li> -->
		</ul>
	</div>
	<div class="absolute bottom-0 left-0 justify-center p-4 space-x-4 w-full lg:flex bg-gray z-20">
		<span class="text-xs opacity-50">version {{version}}</span>
	</div>
</aside>