import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './auth.guard';


const routes: Routes = [
	{
		path: '', redirectTo: '/dashboard', pathMatch: 'full',
	},
	{
		path: 'dashboard', component: DashboardComponent, data: { breadcrumb: 'Dashboard' },
	},
	{
		path: 'organizations', loadChildren: () => import('./organizations/organizations.module').then(m => m.OrganizationsModule)
	},
	{
		path: 'applications', loadChildren: () => import('./applications/applications.module').then(m => m.ApplicationsModule)
	},
	{
		path: '**', component: PageNotFoundComponent
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
