import { Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { computePosition, offset, flip, shift, Placement, autoUpdate } from '@floating-ui/dom'
import { AuthService } from 'src/app/auth.service';
import { Observable } from 'rxjs'
import { Select } from '@ngxs/store';
import { UserState } from 'src/app/shared/state/user/user.state';
import { User } from 'src/app/shared/models/user/user.model';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
	isOpen: boolean = false;
	width: number = 0;
	position: Placement = "bottom-start";

	@ViewChild('dropdown') dropdown!: ElementRef;
	@ViewChild('button') button!: ElementRef;

	@Select(UserState.getUser) $user: Observable<User>;
	user: User;

	constructor(private authService: AuthService) { }

	get userName() {
		return `${this.user.first_name} ${this.user.last_name}`;
	}

	get userEmail() {
		return this.user.email;
	}

	ngOnInit(): void {
		this.$user.subscribe((user: User) => this.user = user);
	}

	toggle() {
		this.isOpen = !this.isOpen;
		this.calculatePosition();
	}

	calculatePosition() {
		computePosition(this.button.nativeElement, this.dropdown.nativeElement,
			{
				placement: this.position,
				middleware: [offset(8), flip(), shift()]
			}
		).then(({ x, y }: { x: number, y: number }) => {
			this.dropdown.nativeElement.style.left = x + 'px';
			this.dropdown.nativeElement.style.top = y + 'px';
			this.width = this.button.nativeElement.offsetWidth;
		});
	}

	ngAfterViewInit() {
		autoUpdate(this.button.nativeElement, this.dropdown.nativeElement, () => {
			if (!this.isOpen) return;
			this.calculatePosition();
		});
	}

	// Onclick outside the dropdown, close it
	@HostListener('document:click', ['$event'])
	clickout(event: Event) {
		if (!this.dropdown.nativeElement.contains(event.target) && this.isOpen && !this.button.nativeElement.contains(event.target)) {
			this.isOpen = false;
		}
	}

	logout() {
		this.authService.logout();
	}
}
