<nav class="border-b border-secondary px-4 py-2.5 fixed left-0 right-0 top-0 z-30 bg-gray">
	<div class="flex flex-wrap justify-between items-center">
		<div class="flex justify-start items-center">
			<a href="" class="flex items-center justify-between mr-4">
				<img src="./assets/img/ai_pilot_logo.svg" class="mr-3 h-10" alt="AI Pilot Logo" />
				<span class="self-center text-2xl font-semibold whitespace-nowrap">AI Pilot</span>
			</a>
		</div>
		<div class="flex items-center lg:order-2">
			<button type="button"
				class="flex mx-3 text-sm bg-secondary rounded-full md:mr-0 focus:ring-4 focus:ring-primary-light items-center justify-center overflow-hidden w-8 h-8"
				id="user-menu-button" aria-expanded="false" (click)="toggle()" #button>
				<span class="sr-only">Open user menu</span>
				<!-- <img class="w-8 h-8 rounded-full" src="https://xsgames.co/randomusers/assets/avatars/male/69.jpg" alt="user photo" /> -->
				<fa-icon size="2xl" [icon]="['fas', 'user']"></fa-icon>
			</button>
			<!-- Dropdown menu -->
			<div class="absolute z-50 my-4 min-w-fit text-primary list-none bg-gray rounded divide-y divide-secondary shadow" #dropdown
				[style.display]="isOpen ? 'block' : 'none'" [style.width]="width + 'px'">
				<div class="py-3 px-4">
					<span class="block text-sm font-semibold text-primary">{{ userName }}</span>
					<span class="block text-sm text-primary truncate">{{ userEmail }}</span>
				</div>
				<ul class="py-1 text-primary" aria-labelledby="dropdown">
					<!-- <li>
						<a routerLink="profile" class="block py-2 px-4 rounded text-sm hover:bg-secondary">
							My profile
						</a>
					</li> -->
					<li>
						<a href="https://auth.cloud.aeotec.com/settings" class="block py-2 px-4 rounded text-sm hover:bg-secondary" target="_blank">
							Account settings
							<fa-icon class="stroke-white text-primary" [icon]="['fas', 'up-right-from-square']"></fa-icon>
						</a>
					</li>
				</ul>
				<ul class="py-1 text-primary" aria-labelledby="dropdown">
					<li>
						<button type="button" (click)="logout()" class="block py-2 px-4 rounded text-sm hover:bg-secondary w-full text-left">
							Sign out
						</button>
					</li>
				</ul>
			</div>
		</div>
	</div>
</nav>