<div class="mb-4">
	<h1 class="font-semibold text-2xl">
		Dashboard
	</h1>
</div>
<div class="mb-8">
	<div class="before:border-t before:border-secondary before:content-[''] before:absolute before:w-full before:left-0"></div>
	<div class="flex flex-row flex-nowrap py-4">
		<div class="basis-1/3">
			<div class="opacity-50">
				Gateways
			</div>
			<div class="text-2xl font-bold">
				{{ globalStatistics.gateways.total }}
			</div>
		</div>
		<div class="basis-1/3">
			<div class="opacity-50">
				Devices
			</div>
			<div class="text-2xl font-bold">
				{{ globalStatistics.devices.total }}
			</div>
		</div>
		<div class="basis-1/3">
			<div class="opacity-50">
				Counted events today
			</div>
			<div class="text-2xl font-bold">
				{{ globalStatistics.events.devices + globalStatistics.events.gateways }}
			</div>
		</div>
	</div>
	<div class="before:border-t before:border-secondary before:content-[''] before:absolute before:w-full before:left-0"></div>
</div>
<div class="">
	<h3 class="opacity-50">Health</h3>
	<div class="flex flex-row flex-nowrap py-4">
		<div class="basis-1/3 flex flex-row flex-nowrap gap-4">
			<div class="basis-24">
				<canvas id="online_state_chart" width="96"></canvas>
			</div>
			<div class="flex flex-col justify-center">
				<div class="opacity-50">
					Gateway online state
				</div>
				<div class="text-2xl font-bold">
					{{ globalStatistics?.gateways.online }} of {{ globalStatistics?.gateways.total }} Gateways are online
				</div>
			</div>
		</div>
		<div class="basis-1/3 flex flex-row flex-nowrap gap-4">
			<div class="basis-24">
				<canvas id="device_online_state_chart" width="96"></canvas>
			</div>
			<div class="flex flex-col justify-center">
				<div class="opacity-50">
					Device online state
				</div>
				<div class="text-2xl font-bold">
					{{ globalStatistics?.devices.online }} of {{ globalStatistics?.devices.total }} Devices are online
				</div>
			</div>
		</div>
		<!-- <div class="basis-1/3 flex flex-row flex-nowrap gap-4">
			<div class="basis-24">
				<canvas id="device_online_state_chart" width="96"></canvas>
			</div>
			<div class="flex flex-col justify-center">
				<div class="opacity-50">
					Pending Alarms
				</div>
				<div class="text-2xl font-bold">
					210 of 260
				</div>
			</div>
		</div> -->
	</div>
</div>
<div class="my-4">
	<h1>Gateway events</h1>
	<div class="flex items-center justify-between pb-4">
		<div>

		</div>
		<label for="table-search" class="sr-only">Search</label>
		<div class="relative">
			<div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
				<fa-icon [icon]="['fas', 'magnifying-glass']"></fa-icon>
			</div>
			<input type="text" id="table-search-users"
				class="block pl-10 w-80 appearance-none border-2 border-secondary rounded py-2 px-3 text-primary leading-tight focus:outline-none focus:border-primary-light"
				placeholder="Search" [(ngModel)]="term" (keyup)="onSearchEvent()" />
		</div>
	</div>
	<!-- <app-pagination [total]="($filter | async)?.total" [current_page]="($filter | async)?.page" [per_page]="($filter | async)?.per_page"
		(page)="pageChange($event)" (perPageChanged)="perPageChange($event)"></app-pagination>
	<app-table [dataSource]="$gatewaysEventsTableData" [tableColumns]="gatewaysEventsTableColumns" (sortChange)="sortChanged($event)"></app-table>
	<app-pagination [total]="($filter | async)?.total" [current_page]="($filter | async)?.page" [per_page]="($filter | async)?.per_page"
		(page)="pageChange($event)" (perPageChanged)="perPageChange($event)"></app-pagination> -->


	<div class="flex items-center justify-end">
		<p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="($filter | async)?.per_page"
			[totalRecords]="($filter | async)?.total" [rowsPerPageOptions]="rowsPerPageOptions" [showCurrentPageReport]="true"
			currentPageReportTemplate="{first} - {last} of {totalRecords}"></p-paginator>
	</div>
	<p-table #table [value]="gatewaysEventsTableData" (onLazyLoad)="sortChanged($event)" [customSort]="true" sortMode="single" [lazy]="true"
		[lazyLoadOnInit]="false" [loading]="loading">
		<ng-template pTemplate="loadingicon">
			<fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
		</ng-template>
		<ng-template pTemplate="sorticon" field="col.field" let-sortOrder>
			<fa-icon [icon]="['fas', 'sort']" *ngIf="sortOrder === 0"></fa-icon>
			<fa-icon [icon]="['fas', 'sort-up']" *ngIf="sortOrder === 1"></fa-icon>
			<fa-icon [icon]="['fas', 'sort-down']" *ngIf="sortOrder === -1"></fa-icon>
		</ng-template>
		<ng-template pTemplate="header">
			<tr>
				<th pSortableColumn="gateway">Gateway <p-sortIcon field="gateway"></p-sortIcon></th>
				<th pSortableColumn="device">Device <p-sortIcon field="device"></p-sortIcon></th>
				<th pSortableColumn="old_value">Old value <p-sortIcon field="old_value"></p-sortIcon></th>
				<th pSortableColumn="new_value">New value <p-sortIcon field="new_value"></p-sortIcon></th>
				<th pSortableColumn="type">Type <p-sortIcon field="type"></p-sortIcon></th>
				<th pSortableColumn="created_at">Created at <p-sortIcon field="created_at"></p-sortIcon></th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-event>
			<tr>
				<td>
					<a [routerLink]="['/gateways', event.id]" pButton class="p-button-text">
						{{ event.gateway }}
					</a>
				</td>
				<td>{{ event.device }}</td>
				<td>{{ event.old_value }}</td>
				<td>{{ event.new_value }}</td>
				<td>{{ event.type }}</td>
				<td>{{ event.created_at }}</td>
			</tr>
		</ng-template>
	</p-table>
</div>