import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Store, Select } from '@ngxs/store';


import { Chart } from 'chart.js/auto';

import { ApiService } from '../shared/services/api/api.service';

import { GatewaysEvents } from '../shared/state/gateways_events/gateways_events.actions';
import { GatewaysEventsState } from '../shared/state/gateways_events/gateways_events.state';
import { Event } from '../shared/models/event/event.model';
import { OrganizationState } from '../shared/state/organization/organization.state';
import { Organization } from '../shared/state/organization/organization.model';
import { Organization as OrganizationAction } from '../shared/state/organization/organization.actions';

import { PaginatorState } from 'primeng/paginator';
import { SortMeta } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';


@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	chart: any;
	chart2: any;

	organization: any;

	gatewaysEventsTableData: any;
	gatewaysEventsTableColumns: any;

	filter: any;
	rowsPerPageOptions: number[] = [10, 25, 50, 100];

	term: string;
	loading: boolean = false;

	first: number = 0;

	globalStatistics: any = {
		devices: {
			total: 0,
			online: 0
		},
		gateways: {
			total: 0,
			online: 0
		},
		events: {
			gateways: 0,
			devices: 0
		}
	}

	@Select(GatewaysEventsState.selectGatewayEvents) $gatewaysEvents: Observable<Event[]>;
	@Select(GatewaysEventsState.filter) $filter: Observable<any>;

	@Select(OrganizationState.selectOrganizations) $organization: Observable<Organization[]>;


	constructor(private apiService: ApiService, private store: Store) { }

	ngOnInit(): void {

		this.store.dispatch(new GatewaysEvents.SetFilter({ per_page: 10, page: 1, q: '', total: '' }))
		this.store.dispatch(new GatewaysEvents.FetchAll);

		this.store.dispatch(new OrganizationAction.FetchAll);

		this.$filter.subscribe((data) => {
			this.filter = data;
		});

		this.gatewaysEventsTableColumns = [
			{
				columnDef: 'gateway',
				header: 'Gateway',
				isSortable: true,
				isLink: true
			},
			{
				columnDef: 'device',
				header: 'Device',
				isSortable: true,
				islink: true
			},
			{
				columnDef: 'old_value',
				header: 'Old Value',
				isSortable: true
			},
			{
				columnDef: 'new_value',
				header: 'New Value',
				isSortable: true
			},
			{
				columnDef: 'type',
				header: 'Type',
				isSortable: true
			},
			{
				columnDef: 'created_at',
				header: 'Created at date',
				isSortable: true
			}
		];

		this.$gatewaysEvents.subscribe((events: Event[]) => {

			this.gatewaysEventsTableData = events.map((e: Event) => {
				return {
					id: e.gateway.id,
					gateway: e.gateway.name ? e.gateway.name : e.gateway.third_party.name,
					device: e.device && e.device.name ? e.device.name : (e.device && e.device.third_party && e.device.third_party.name ? e.device.third_party.name : '-'),
					new_value: e.new_value ?? '-',
					old_value: e.old_value ?? '-',
					type: e.type ? `${e.type.target}_${e.type.event}` : '-',
					created_at: e.created_at
				}
			}) as any;
		});


		this.$organization.subscribe((organizations: Organization[]) => {
			console.log('organizations', organizations);

			const organization = organizations[0];

			this.organization = organization;
			if (!this.organization) return;
			console.log('this.organization', this.organization);

			this.globalStatistics.devices.total = 0;
			this.globalStatistics.devices.online = 0;
			this.globalStatistics.gateways.total = 0;
			this.globalStatistics.gateways.online = 0;
			this.globalStatistics.events.devices = 0;
			this.globalStatistics.events.gateways = 0;

			organizations.forEach((organization: Organization) => {
				this.globalStatistics.devices.total += organization.statistics.devices.total;
				this.globalStatistics.devices.online += organization.statistics.devices.online;

				this.globalStatistics.gateways.total += organization.statistics.gateways.total;
				this.globalStatistics.gateways.online += organization.statistics.gateways.online;

				this.globalStatistics.events.devices += organization.statistics.events.devices;
				this.globalStatistics.events.gateways += organization.statistics.events.gateways;
			});

			console.log('this.globalStatistics', this.globalStatistics);

			const chartData: any = {
				type: 'doughnut',
				data: {
					labels: [
						'Offline',
						'Online'
					],
					datasets: [{
						data: [this.globalStatistics.gateways.total - this.globalStatistics.gateways.online, this.globalStatistics.gateways.online],
						backgroundColor: [
							'rgb(255, 0, 0)',
							'rgb(0, 255, 0)'
						],
						hoverOffset: 4
					}]
				},
				options: {
					cutout: '60%',
					responsive: true,
					aspectRatio: 1,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false
						}
					}
				}
			};

			if (this.chart) {
				this.chart.clear();
				this.chart.destroy();
				this.chart = null;
			}

			this.chart = new Chart('online_state_chart', chartData);

			const deviceOnlineStateChartData: any = {
				type: 'doughnut',
				data: {
					labels: [
						'Offline',
						'Online'
					],
					datasets: [{
						data: [this.globalStatistics.devices.total - this.globalStatistics.devices.online, this.globalStatistics.devices.online],
						backgroundColor: [
							'rgb(255, 0, 0)',
							'rgb(0, 255, 0)'
						],
						hoverOffset: 4
					}]
				},
				options: {
					cutout: '60%',
					responsive: true,
					aspectRatio: 1,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: false
						}
					}
				}
			};

			if (this.chart2) {
				this.chart2.clear();
				this.chart2.destroy();
				this.chart2 = null;
			}

			this.chart2 = new Chart('device_online_state_chart', deviceOnlineStateChartData);
		});
	}

	onSearchEvent() {
		this.store.dispatch(new GatewaysEvents.SetFilter({ ...this.filter, q: this.term }));
		this.store.dispatch(new GatewaysEvents.FetchAll());
	}

	onPageChange(event: PaginatorState) {
		console.log(event);
		this.first = event.first as number;
		const page: number = event.page ? event.page + 1 : 1;
		this.store.dispatch(new GatewaysEvents.SetFilter({ ...this.filter, page: page, per_page: event.rows }));
		this.store.dispatch(new GatewaysEvents.FetchAll());
	}

	perPageChange(perPageNumber: any) {
		this.store.dispatch(new GatewaysEvents.SetFilter({ ...this.filter, per_page: perPageNumber }));
		this.store.dispatch(new GatewaysEvents.FetchAll());
	}

	sortChanged(sorting: any) {
		const s = Object.entries(sorting).filter((e: any) => e[1] !== 'default').map((e: any) => e.join('.'));
		this.store.dispatch(new GatewaysEvents.SetFilter({ ...this.filter, s: s }));
		this.store.dispatch(new GatewaysEvents.FetchAll());
	}

}
