import { Injectable } from "@angular/core";
import { Action, Selector, StateContext, State, createSelector } from "@ngxs/store";
import { Device, State as DeviceState } from "../../models/device/device.model";
import { GatewayService } from "../../services/gateway/gateway.service";
import { GatewayDevices } from "./gateway_devices.actions";
import { tap } from "rxjs";
import { Gateways } from "../gateway/gateway.actions";


export class GatewayDeviceEntry {
	filter: {
		page: number,
		per_page: number,
		q: string,
		total: number,
		s: []
	}
	devices: Device[]
}

export class GatewayDevicesStateModel {
	gateway_devices: { [key: string]: GatewayDeviceEntry }
}

@State<GatewayDevicesStateModel>({
	name: 'gatewaydevicesstate',
	defaults: {
		gateway_devices: {}
	}
})

@Injectable()
export class GatewayDevicesState {
	constructor(private gatewayService: GatewayService) { }


	@Selector()
	static getGatewayDevices(state: GatewayDevicesStateModel) {
		return (gateway_id: number) => {
			let devices: any = state.gateway_devices[gateway_id] ? state.gateway_devices[gateway_id].devices : [];
			return devices;
		}
	}

	@Selector()
	static getGatewayDevice(state: GatewayDevicesStateModel) {
		return (gateway_id: number, device_id: number) => {
			let device: any = state.gateway_devices[gateway_id] ? state.gateway_devices[gateway_id].devices.find((d: Device) => d.id == device_id) : {};
			return device;
		}
	}

	@Action(GatewayDevices.FetchForGateway)
	getGatewayDevices(ctx: StateContext<GatewayDevicesStateModel>, action: GatewayDevices.FetchForGateway) {
		return this.gatewayService.fetchDevicesForGateway(action.gateway_id).pipe(tap((returnData: any) => {
			const devices = returnData.data.map((d: Device) => {

				d.states = d.states.map((s: DeviceState) => {
					return {
						...s,
						progress: false
					}
				});

				return {
					...d, ...{
						progress: false,
						battery: d.states.find((s: DeviceState) => s.type.smartthings_capability == 'battery' && s.type.smartthings_attribute == 'battery')
					}
				}
			});

			const state = ctx.getState();
			ctx.patchState({
				...state,
				gateway_devices: {
					...state.gateway_devices,
					[action.gateway_id]: {
						devices: devices,
						filter: {
							page: returnData.current_page,
							per_page: returnData.per_page,
							total: returnData.total
						}
					}
				}
			});
		}))
	}

	@Action(GatewayDevices.UpdateDevice)
	updateGatewayDevice(ctx: StateContext<GatewayDevicesStateModel>, action: GatewayDevices.UpdateDevice) {
		const state = ctx.getState();

		const devices = state.gateway_devices[action.gateway_id].devices;

		const deviceIndex = devices.findIndex((d: Device) => d.id == action.device_id);

		devices[deviceIndex] = {
			...devices[deviceIndex],
			...action.data
		};

		return ctx.patchState({
			...state,
			gateway_devices: {
				...state.gateway_devices,
				[action.gateway_id]: {
					...state.gateway_devices[action.gateway_id],
					devices: devices
				}
			}
		});
	}


	@Action(GatewayDevices.UpdateDeviceState)
	updateGatewayDeviceState(ctx: StateContext<GatewayDevicesStateModel>, action: GatewayDevices.UpdateDeviceState) {
		const state = ctx.getState();

		if (!state.gateway_devices[action.gateway_id]) return;

		const devices = state.gateway_devices[action.gateway_id].devices;

		const deviceIndex = devices.findIndex((d: Device) => d.id == action.device_id);

		const device: Device = devices[deviceIndex]
		if (!device) return;

		const deviceStateIndex = device.states.findIndex((s: DeviceState) => s.component == action.component
			&& s.type.smartthings_capability == action.capability
			&& s.type.smartthings_attribute == action.attribute);

		if (action.hasOwnProperty('created_at')) {
			devices[deviceIndex].last_activity_at = action.created_at;
			devices[deviceIndex].states[deviceStateIndex].third_party_updated_at = action.created_at;
		}

		if (action.hasOwnProperty('value')) {
			devices[deviceIndex].states[deviceStateIndex].progress = false;
			devices[deviceIndex].states[deviceStateIndex].value = action.value;
		}

		const isProgress = devices[deviceIndex].states.some((s: DeviceState) => {
			return s.progress
		});

		devices[deviceIndex].progress = isProgress;

		ctx.patchState({
			...state,
			gateway_devices: {
				...state.gateway_devices,
				[action.gateway_id]: {
					...state.gateway_devices[action.gateway_id],
					devices: devices
				}
			}
		});

		return ctx.dispatch(new Gateways.Update(action.gateway_id, { last_activity_at: action.created_at }))
	}


	@Action(GatewayDevices.UpdateDeviceStateProgress)
	updateGatewayDeviceStateProgress(ctx: StateContext<GatewayDevicesStateModel>, action: GatewayDevices.UpdateDeviceStateProgress) {
		const state = ctx.getState();

		if (!state.gateway_devices[action.gateway_id]) return;

		const devices = state.gateway_devices[action.gateway_id].devices;

		const deviceIndex = devices.findIndex((d: Device) => d.id == action.device_id);

		const device: Device = devices[deviceIndex]

		const deviceStateIndex = device.states.findIndex((s: DeviceState) => s.component == action.component
			&& s.type.smartthings_capability == action.capability
			&& s.type.smartthings_attribute == action.attribute);

		devices[deviceIndex].states[deviceStateIndex].progress = action.progress;

		const isProgress = devices[deviceIndex].states.some((s: DeviceState) => {
			return s.progress
		});

		devices[deviceIndex].progress = isProgress;

		ctx.patchState({
			...state,
			gateway_devices: {
				...state.gateway_devices,
				[action.gateway_id]: {
					...state.gateway_devices[action.gateway_id],
					devices: devices
				}
			}
		});
	}

}