import { Injectable } from "@angular/core";
import { HttpParams } from '@angular/common/http';

import { Action, Selector, StateContext, State } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { tap } from "rxjs";

import { OrganizationService } from "src/app/shared/services/organization/organization.service";
import { Organization } from "./organization.actions";

import { Organization as OrganizationModel } from "./organization.model";

export class OrganizationStateModel {
	filter: any
	organizations: any
}

@State<OrganizationStateModel>({
	name: 'organizationstate',
	defaults: {
		filter: {
			page: '',
			per_page: '',
			q: '',
			total: '' // raus
		},
		organizations: []
	}
})

@Injectable()
export class OrganizationState {
	constructor(private organizationService: OrganizationService) { }

	@Selector()
	static currentPage(state: OrganizationStateModel): number {
		return state.filter.page;
	}

	@Selector()
	static itemsOnPage(state: OrganizationStateModel): number {
		return state.filter.per_page;
	}

	@Selector()
	static query(state: OrganizationStateModel): string {
		return state.filter.q;
	}

	@Selector()
	static total(state: OrganizationStateModel): string {
		return state.filter.total;
	}

	@Selector()
	static filter(state: OrganizationStateModel): string {
		return state.filter;
	}

	@Selector()
	static selectOrganizations(state: OrganizationStateModel) {
		return state.organizations;
	}

	@Selector()
	static getOrganization(state: OrganizationStateModel) {
		return (id: number) => {
			return state.organizations.find((o: OrganizationModel) => {
				return o.id == id
			});
		};
	}

	@Action(Organization.SetFilter)
	setFilter(ctx: StateContext<OrganizationStateModel>, action: Organization.SetFilter) {
		return ctx.setState(patch<OrganizationStateModel>({
			filter: action.filter
		}));
	}

	@Action(Organization.FetchAll)
	getOrganizations(ctx: StateContext<OrganizationStateModel>) {
		const state = ctx.getState();

		const options = {
			params: new HttpParams({
				fromObject: state.filter as any
			})
		}
		return this.organizationService.fetchOrganizations(options).pipe(tap((returnData: any) => {
			const state = ctx.getState();
			ctx.patchState({
				...state,
				organizations: returnData.data,
				filter: {
					...state.filter,
					page: returnData.current_page,
					per_page: returnData.per_page,
					total: returnData.total,
					q: ''
				}
			});
		}));
	}
}
