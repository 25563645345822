import { Injectable } from "@angular/core";
import { HttpParams } from '@angular/common/http';

import { Action, Selector, StateContext, State } from "@ngxs/store";
import { tap } from "rxjs";

import { GatewayService } from "src/app/shared/services/gateway/gateway.service";
import { GatewayEvents } from "./gateway_events.actions";

import { Event } from "../../models/event/event.model";

export class GatewayEventsEntry {
	filter: {
		page: number,
		per_page: number,
		q: string,
		total: number,
		s: []
	}
	events: Event[]
}

export class GatewayEventsStateModel {
	gateway_events: { [key: string]: GatewayEventsEntry }
}

@State<GatewayEventsStateModel>({
	name: 'gatewayeventsstate',
	defaults: {
		gateway_events: {}
	}
})

@Injectable()
export class GatewayEventsState {
	constructor(private gatewayService: GatewayService) { }

	@Selector()
	static filter(state: GatewayEventsStateModel) {
		return (gateway_id: number) => {
			const filter = state.gateway_events[gateway_id] ? state.gateway_events[gateway_id].filter : {
				page: 0,
				per_page: 0,
				q: '',
				total: 0,
				s: []
			};
			return filter;
		};
	}

	@Selector()
	static getGatewayEvents(state: GatewayEventsStateModel) {
		return (gateway_id: number) => {
			let events: any = state.gateway_events[gateway_id] ? state.gateway_events[gateway_id].events : [];
			return events;
		}
	}

	@Action(GatewayEvents.FetchForGateway)
	fetchGatewayEvents(ctx: StateContext<GatewayEventsStateModel>, action: GatewayEvents.FetchForGateway) {
		const state = ctx.getState();
		let filter: any = state.gateway_events[action.gateway_id] ? state.gateway_events[action.gateway_id].filter : {};

		filter = {
			...filter,
			s: filter.s ? filter.s.join(',') : []
		}

		const options = {
			params: new HttpParams({
				fromObject: filter
			})
		}
		return this.gatewayService.fetchGatewayEvents(action.gateway_id, options).pipe(tap((returnData: any) => {
			console.log('returnData events', returnData);

			const events = returnData.data;
			const state = ctx.getState();

			ctx.patchState({
				...state,
				gateway_events: {
					...state.gateway_events,
					[action.gateway_id]: {
						events: events,
						filter: {
							page: returnData.current_page,
							per_page: returnData.per_page,
							total: returnData.total
						}
					}
				}
			});
		}));
	}

	@Action(GatewayEvents.SetFilter)
	setFilter(ctx: StateContext<GatewayEventsStateModel>, action: GatewayEvents.SetFilter) {
		const state = ctx.getState();
		const gateway_events = state.gateway_events[action.gateway_id];

		return ctx.patchState({
			...state,
			gateway_events: {
				...state.gateway_events,
				[action.gateway_id]: {
					...gateway_events,
					filter: {
						...gateway_events.filter,
						...action.filter
					}
				}
			}
		});
	}
}
