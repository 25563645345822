import { Component, OnInit } from '@angular/core';

import { environment } from '@env/environment';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

	version: string = environment.version;

	constructor() { }

	ngOnInit(): void {

	}

}
