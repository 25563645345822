import { Injectable } from "@angular/core";
import { Action, Selector, StateContext, State } from "@ngxs/store";
import { updateItem, patch } from "@ngxs/store/operators";
import { filter, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';


import { GatewayService } from "src/app/shared/services/gateway/gateway.service";
import { Gateways } from './gateway.actions';
import { Gateway } from './gateway.model';


export class GatewayStateModel {
	filter: any
	gateways: any
}

@State<GatewayStateModel>({
	name: 'gatewaystate',
	defaults: {
		filter: {
			page: '',
			per_page: '',
			q: '',
			total: '',
			f: ''
		},
		gateways: []
	}
})

@Injectable()
export class GatewayState {
	constructor(private gatewayService: GatewayService) { }

	@Selector()
	static currentPage(state: GatewayStateModel): number {
		return state.filter.page;
	}

	@Selector()
	static itemsOnPage(state: GatewayStateModel): number {
		return state.filter.per_page;
	}

	@Selector()
	static query(state: GatewayStateModel): string {
		return state.filter.q;
	}

	@Selector()
	static total(state: GatewayStateModel): string {
		return state.filter.total;
	}

	@Selector()
	static filter(state: GatewayStateModel): string {
		return state.filter;
	}

	@Selector()
	static selectGateways(state: GatewayStateModel) {
		return state.gateways;
	}

	@Selector()
	static getGateway(state: GatewayStateModel) {
		return (id: number) => {
			return state.gateways.find((g: Gateway) => {
				return g.id == id
			});
		};
	}

	@Action(Gateways.SetFilter)
	setFilter(ctx: StateContext<GatewayStateModel>, action: Gateways.SetFilter) {
		return ctx.setState(patch<GatewayStateModel>({
			filter: action.filter
		}));
	}

	@Action(Gateways.FetchAll)
	getGateways(ctx: StateContext<GatewayStateModel>) {
		const state = ctx.getState();

		const options = {
			params: new HttpParams({
				fromObject: state.filter as any
			})
		}
		return this.gatewayService.fetchGateways(options).pipe(tap((returnData: any) => {
			const state = ctx.getState();
			ctx.patchState({
				...state,
				gateways: returnData.data,
				filter: {
					...state.filter,
					page: returnData.current_page,
					per_page: returnData.per_page,
					total: returnData.total
				}
			});
		}));
	}

	@Action(Gateways.Update)
	updateGateway(ctx: StateContext<GatewayStateModel>, action: Gateways.Update) {
		return ctx.setState(patch({
			gateways: updateItem((gateway: Gateway) => gateway.id == action.gateway_id, patch({ ...action.data }))
		}));
	}
}