import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';

// Third Party
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthConfig } from 'angular-oauth2-oidc';

import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { BreadcrumbModule } from 'xng-breadcrumb';

//
import { oauthConfig } from './oauth.config';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SharedModule } from './shared/shared.module';
import { authAppInitializerFactory } from './auth-app-initializer.factory';
import { AuthService } from './auth.service';
import { environment } from '@env/environment';


// States
import { GatewayState } from './shared/state/gateway/gateway.state';
import { GatewaysEventsState } from './shared/state/gateways_events/gateways_events.state';
import { GatewayEventsState } from './shared/state/gateway_events/gateway_events.state';
import { GatewayDevicesState } from './shared/state/gateway_devices/gateway_devices.state';
import { DeviceEventsState } from './shared/state/device_events/device_events.state';
import { UserState } from './shared/state/user/user.state';
import { OrganizationState } from './shared/state/organization/organization.state';
import { GatewayDevicesGraphsState } from './shared/state/gateway_devices_graphs/gateway_devices_graphs.state';



@NgModule({
	declarations: [
		AppComponent,
		DashboardComponent,
		PageNotFoundComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FormsModule,
		TableModule,
		PaginatorModule,
		SharedModule,
		OAuthModule.forRoot({
			resourceServer: {
				allowedUrls: [environment.api_base_url],
				sendAccessToken: true
			}
		}),
		FontAwesomeModule,
		BreadcrumbModule,
		environment.production ? [] : NgxsReduxDevtoolsPluginModule.forRoot(),
		NgxsModule.forRoot([GatewayState, GatewaysEventsState, GatewayEventsState, OrganizationState, GatewayDevicesState, DeviceEventsState, GatewayDevicesGraphsState, UserState]),
		NgxsStoragePluginModule.forRoot({
			key: [GatewayState, GatewaysEventsState, GatewayEventsState, OrganizationState, GatewayDevicesState, DeviceEventsState, GatewayDevicesGraphsState, UserState]
		})
	],
	providers: [
		{ provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthService], multi: true },
		{ provide: AuthConfig, useValue: oauthConfig }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		library.addIconPacks(fas, far, fab);
	}
}
