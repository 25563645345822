import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

// Layouts
import { HeaderComponent } from './components/layouts/header/header.component';
import { SidebarComponent } from './components/layouts/sidebar/sidebar.component';
import { DeviceWidgetComponent } from './components/device-widget/device-widget.component';
import { ChartWidgetComponent } from './components/chart-widget/chart-widget.component';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';


import { OnHoldDirective } from './directives/on-hold/on-hold.directive';

import { ButtonModule } from 'primeng/button';

@NgModule({
	declarations: [
		HeaderComponent,
		SidebarComponent,
		DeviceWidgetComponent,
		ChartWidgetComponent,
		OnHoldDirective,
	],
	imports: [
		CommonModule,
		RouterModule,
		FontAwesomeModule,
		FormsModule,
		ButtonModule
	],
	exports: [
		RouterModule,
		HeaderComponent,
		SidebarComponent,
		DeviceWidgetComponent,
		ChartWidgetComponent,
		OnHoldDirective
	]
})
export class SharedModule {
	constructor(library: FaIconLibrary) {
		library.addIconPacks(fas, far, fab);
	}
}
