export namespace Gateways {
	export class FetchAll {
		static readonly type = '[Gateway] Fetch all';
	}

	export class Edit {
		static readonly type = '[Gateway] Edit';
	}

	export class Update {
		static readonly type = '[Gtaeway] update';
		constructor(public gateway_id: number, public data: any) { }
	}

	export class SetFilter {
		static readonly type = '[Gateway] Set Filter';
		constructor(public filter: any) { }
	}
}